import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <a href="https://www.mas.gov.sg/" target="_blank">MAS</a> + <a href="https://aws.amazon.com" target="_blank">AWS</a> + <a href="https://labs.mbanq.io" target="_blank">Mbanq Labs</a> = Your Bank
    <h1>{`Intro`}</h1>
    <p>{`We cannot stop emphasizing how happy we're that we chose Singapore as the place for our first Banking-as-a-Service accelerator - `}<a href="https://labs.mbanq.io" target="_blank">{`Mbanq Labs`}</a>{`.`}</p>
    <p>{`The rich historical and cultural heritage of this place combined with the
unprecedented diversity fills us with pride and energy to keep getting better.`}</p>
    <h1>{`Diversity in Banking`}</h1>
    <p>{`The banking landscape of Singapore has been dominated by the big established banks. But the situation is about to change.`}</p>
    <p>{`You probably heard about the announcement made by `}<a href="https://www.mas.gov.sg/" target="_blank">{`The Monetary Authority of
Singapore (MAS)`}</a>{` to issue up to five new digital bank licences - read more about
it `}<a href="https://www.mas.gov.sg/news/media-releases/2019/mas-to-issue-up-to-five-digital-bank-licences" target="_blank">{`here`}</a>{`.`}</p>
    <p>{`Singapore has been working really hard for the past 5 years to establish itself
as the FinTech capital of the ASEAN and the issue of the additional banking
licenses only proves the point that Singapore is taking it very seriously.`}</p>
    <p>{`As the article on the MAS website states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The entry of new digital players will add diversity and help strengthen Singapore’s banking system in the digital economy of the future.`}</p>
    </blockquote>
    <h1>{`"I have a dream"`}</h1>
    <p>{`If you ever dreamt about building the bank for the next generation, now it is the proper time to do it.`}</p>
    <p>{`We are the firm believers that the announcement about the 5 new banking licenses
is just the beginning of something really big.`}</p>
    <p>{`Singapore has the best cards to become a global banking hub for the ASEAN and
could export its success later one to the rest of the world.`}</p>
    <p>{`If you are ready to start your entrepreneurial journey then don't look any further and join `}<a href="https://labs.mbanq.io/application/startups/" target="_blank">{`Mbanq Labs`}</a>{`.`}</p>
    <p>{`We are about to on-board the 2nd batch of the startups into our accelerator
program beginning next month.`}</p>
    <p>{`Mbanq Labs is powered by `}<a href="https://mbanq.com" target="_blank">{`Mbanq`}</a>{` - the provider of the digital and cloud banking solutions.`}</p>
    <p>{`Every startup that joins the `}<a href="https://labs.mbanq.io/program" target="_blank">{`Mbanq Labs program`}</a>{` is eligible for the Mbanq Cloud and AWS credits that could potentially allow you to start and scale your bank without spending any money on the technology.`}</p>
    <p><strong parentName="p">{`Our friends at `}<a href="https://aws.amazon.com" target="_blank">{`AWS`}</a>{` are ready to support every startup that is using AWS to run their bank with the digital banking application.`}</strong></p>
    <h1>{`Mbanq Cloud`}</h1>
    <p>{`Mbanq Cloud provides full-stack solution for digital banks and consists of following parts:`}</p>
    <ul>
      <li parentName="ul">{`Core Banking System`}</li>
      <li parentName="ul">{`Web-based back-office application`}</li>
      <li parentName="ul">{`Cloud-banking management application `}<a href="https://console.cloud.mbanq.com/" target="_blank">{`Mbanq Console`}</a></li>
      <li parentName="ul">{`Open RESTful API `}<a href="https://api-docs.cloud.mbanq.com/" target="_blank">{`API Docs`}</a></li>
      <li parentName="ul">{`Web-banking application for the end customers`}</li>
    </ul>
    <p>{`Mbanq Cloud also provides a free evaluation environment where everyone can register and test our products: `}<a parentName="p" {...{
        "href": "https://console.cloud.mbanq.com/"
      }}>{`https://console.cloud.mbanq.com/`}</a></p>
    <p>{`The open nature of the Mbanq Cloud product let you easily extend the functionality of your bank by connecting 3rd party services to your core banking system through the RESTful API. You can also build your own frontends mobile or web on top of the API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      